import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Home.css";

import { fetchLatestTours, fetchPackages } from "./ApiService";

const Homepagesecond = () => {
  const [oceania, setOceania] = useState([]);
  const [southeast, SetSoutheast] = useState([]);
  const [scandinavia, setScandinavia] = useState([]);
  const [middleeast, setMiddleeast] = useState([]);
  const [europePackages, setEuropePackages] = useState([]);

  useEffect(() => {
    getPackages(3, setEuropePackages);
    getPackages(4, setOceania);
    getPackages(5, SetSoutheast);
    getPackages(6, setScandinavia);
    getPackages(7, setMiddleeast);
  }, []);

  // -----------packeges-----------------------------

  const getPackages = async (id, setState) => {
    try {
      const data = await fetchPackages(id);
      setState(data);
    } catch (error) {
      console.error("Error fetching packages:", error);
    }
  };

  //-----------------------------------------slider setting------------------------------------------------
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.3,
          slidesToScroll: 1,
          autoplaySpeed: 3000,
        },
      },
    ],
  };
  const latestsettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.3,
          slidesToScroll: 1,
          autoplaySpeed: 5000,
        },
      },
    ],
  };

  return (
    <div>
      {/* ------------------------------------------------------- VISA ON --------------------------------------------------------------*/}

      <div className="container text-center my-5">
        <div className="container">
          <h4 className="cards-heading">VISA ON ARRIVAL DESTINATIONS</h4>
          <div className="slider-container w-3/4 m-auto">
            <div className="slider-inner mt-20">
              <Slider {...settings}>
                {southeast.map((pkg, index) => (
                  <Link to={`/SubPackages/${pkg.id}/${pkg.slug}`} key={index}>
                    <div className="package-card h-[450px] text-black rounded-xl">
                      <div className="package-image-container  bg-indigo-500 d-flex justify-center items-center rounded-t-xl">
                        <img
                          src={pkg.image}
                          alt={pkg.name}
                          className="package-image"
                        />
                      </div>
                      <p className="package-name mt-1">{pkg.name}</p>
                    </div>
                  </Link>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
      {/* -------------------------------------------------------EXPLORATIONS--------------------------------------------------------------*/}

      <div
        className="container-fluid bg-registration d-none d-md-block "
        style={{ backgroundColor: "rgb(18, 27, 34)" }}
      >
        <div className="container  d-flex flex-column justify-content-center align-items-center inside-banner">
          <h2 style={{ color: "rgb(42, 204, 101)" }}>EXPLORATIONS FAR AWAY</h2>
          <p
            style={{
              color: "rgb(42, 204, 101)",
              fontSize: "18px",
              fontFamily: "Montserrat,serif",
            }}
          >
            Ideal for 5-14 days trip
          </p>
          <div
            className="row align-items-center"
            style={{
              display: "flex",
              flexDirection: "row",
              height: "400px",
              gap: "20px",
            }}
          >
            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <div className="position-relative d-inline-block">
                <img
                  src="/assets/img/Oceania/perth.webp"
                  className="img-fluid rounded-15"
                  alt="Agra"
                  style={{
                    height: "200px",
                    width: "350px",
                    objectFit: "cover",
                    borderRadius: "20px",
                  }}
                />
                <div className="QUICK-GETAWAy-text">
                  <h1>Perth</h1>
                  <p style={{ color: "white" }}>from₹320</p>
                </div>
              </div>

              <div className="d-flex flex-row gap-10">
                <div className="position-relative d-inline-block">
                  <img
                    src="/assets/img/Oceania/fijii.webp"
                    className="img-fluid rounded-15"
                    alt="Kerala"
                    style={{
                      height: "200px",
                      width: "175px",
                      objectFit: "cover",
                      borderRadius: "20px",
                    }}
                  />
                  <div className="QUICK-GETAWAy-text">
                    <h1>Fijii</h1>
                    <p style={{ color: "white" }}>from₹320</p>
                  </div>
                </div>
                <div className="position-relative d-inline-block">
                  <img
                    src="/assets/img/Oceania/syndey.webp"
                    className="img-fluid rounded-15"
                    alt="Goa"
                    style={{
                      height: "200px",
                      width: "165px",
                      objectFit: "cover",
                      borderRadius: "20px",
                      marginLeft: "10px",
                    }}
                  />
                  <div className="QUICK-GETAWAy-text">
                    <h1>Syndey</h1>
                    <p style={{ color: "white" }}>from₹320</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="position-relative d-none d-md-block">
              <img
                src="/assets/img/Oceania/newsland.webp"
                className="img-fluid rounded-15"
                alt="Himachal"
                style={{
                  height: "410px",
                  width: "350px",
                  objectFit: "cover",
                  borderRadius: "20px",
                }}
              />
              <div className="QUICK-GETAWAy-text-center">
                <h1>Newsland</h1>
                <p style={{ color: "white" }}>from₹320</p>
              </div>
            </div>

            <div className="d-none d-md-flex flex-column gap-10">
              <div className="position-relative d-inline-block">
                <img
                  src="/assets/img/Oceania/quensland.webp"
                  className="img-fluid rounded-15"
                  alt="Mumbai"
                  style={{
                    height: "200px",
                    width: "350px",
                    objectFit: "cover",
                    borderRadius: "20px",
                  }}
                />
                <div className="QUICK-GETAWAy-text">
                  <h1>Quensland</h1>
                  <p style={{ color: "white" }}>from₹320</p>
                </div>
              </div>
              <div className="position-relative d-inline-block ">
                <img
                  src="/assets/img/Oceania/tahiti.webp"
                  className="img-fluid rounded-15"
                  alt="Tamil Nadu"
                  style={{
                    height: "200px",
                    width: "350px",
                    objectFit: "cover",
                    marginTop: "10px",
                    borderRadius: "20px",
                  }}
                />
                <div className="QUICK-GETAWAy-text">
                  <h1>Tahiti</h1>
                  <p style={{ color: "white" }}>from₹320</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* -------------------------------------------------------EXPLORATIONS mobile--------------------------------------------------------------*/}

      <div
        className="container-fluid quick-contain d-block d-lg-none"
        style={{ backgroundColor: "rgb(18, 27, 34)" }}
      >
        <h2
          style={{ color: "rgb(42, 204, 101)", fontWeight: "700" }}
          className="text-uppercase"
        >
          EXPLORATIONS FAR AWAY
        </h2>
        <p
          style={{
            color: "rgb(42, 204, 101)",
            fontSize: "18px",
            fontFamily: "Montserrat,serif",
          }}
        >
          Ideal for 5-14 days trip
        </p>
        <div className="container  quick-getaways">
          <div className="quick-getaways-images">
            <div className="quick-getaways-cards">
              <img
                src="/assets/img/Oceania/perth.webp"
                className="img-fluid rounded-15"
                alt="img"
              />
              <p>Perth</p>
            </div>
            <div className="quick-getaways-cards">
              <img
                src="/assets/img/Oceania/syndey.webp"
                className="img-fluid rounded-15"
                alt="img"
              />
              <p>Sydney</p>
            </div>
            <div className="quick-getaways-cards">
              <img
                src="/assets/img/Oceania/fijii.webp"
                className="img-fluid rounded-15"
                alt="img"
              />
              <p>Fiji</p>
            </div>
          </div>
          <div className="quick-getaways-images">
            <div className="quick-getaways-cards">
              <img
                src="/assets/img/Oceania/newsland.webp"
                className="img-fluid rounded-15"
                alt="img"
              />
              <p>Newzealand</p>
            </div>
            <div className="quick-getaways-cards">
              <img
                src="/assets/img/Oceania/quensland.webp"
                className="img-fluid rounded-15"
                alt="img"
              />
              <p>Queensland</p>
            </div>
            <div className="quick-getaways-cards">
              <img
                src="/assets/img/Oceania/tahiti.webp"
                className="img-fluid rounded-15"
                alt="img"
              />
              <p>Tahiti</p>
            </div>
          </div>
        </div>
      </div>
      {/* -------------------------------------------------------OCEANIA --------------------------------------------------------------*/}
      <div className="container text-center my-5  packages-card">
        <div className="container">
          <h4 className="cards-heading">OCEANIA</h4>
          <div className="slider-container w-3/4 m-auto">
            <div className="slider-inner mt-20">
              <Slider {...settings}>
                {oceania.map((pkg, index) => (
                  <Link to={`/SubPackages/${pkg.id}/${pkg.slug}`} key={index}>
                    <div className="package-card h-[450px] text-black rounded-xl">
                      <div className="package-image-container  bg-indigo-500 d-flex justify-center items-center rounded-t-xl">
                        <img
                          src={pkg.image}
                          alt={pkg.name}
                          className="package-image"
                        />
                      </div>
                      <p className="package-name mt-1">{pkg.name}</p>
                    </div>
                  </Link>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
      {/* -------------------------------------------------------  EUROPEAN HOLIDAYS --------------------------------------------------------------*/}
      <div className="container text-center  ">
        <div className="container">
          <h4 className="cards-heading">EUROPEAN HOLIDAYS</h4>
          <div className="slider-container w-3/4 m-auto">
            <div className="slider-inner mt-20">
              <Slider {...settings}>
                {europePackages.map((pkg, index) => (
                  <Link to={`/SubPackages/${pkg.id}/${pkg.slug}`} key={index}>
                    <div className="package-card h-[450px] text-black rounded-xl">
                      <div className="package-image-container  bg-indigo-500 d-flex justify-center items-center rounded-t-xl">
                        <img
                          src={pkg.image}
                          alt={pkg.name}
                          className="package-image"
                        />
                      </div>
                      <p className="package-name mt-1">{pkg.name}</p>
                    </div>
                  </Link>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>

      {/* -------------------------------------------------------  HOLIDAYS FOR ALL --------------------------------------------------------------*/}

      <div className="container py-5 d-flex flex-column justify-content-center align-items-center ">
        <div
          className="py-5 d-flex flex-column justify-content-center align-items-center Holiday "
          style={{}}
        >
          <h2
            style={{
              color: "white",
              fontWeight: "700",
              fontFamily: "sans-serif",
            }}
          >
            HOLIDAYS FOR ALL
          </h2>
          <p
            className="my-3 mb-5"
            style={{
              color: "rgb(36, 183, 116)",
              fontSize: "28px",
              fontFamily: "Dancing Script",
              lineHeight: "1.2px",
            }}
          >
            Budget
          </p>

          <div className="row align-items-center image-grid">
            <div className="mobile-res">
              <div className="position-relative d-inline-block mx-2 image-item">
                <img
                  src="/assets/img/VisaArrival/maldives.avif"
                  className="img-fluid rounded-15"
                  alt="Agra"
                />
                <div className="image-caption">Maldives</div>
              </div>
              <div className="position-relative d-inline-block mx-2 image-item">
                <img
                  src="/assets/img/VisaArrival/thailand.avif"
                  className="img-fluid rounded-15"
                  alt="Agra"
                />
                <div className="image-caption">Thaliland</div>
              </div>
            </div>
            <div className="mobile-res">
              <div className="position-relative d-inline-block mx-2 image-item">
                <img
                  src="/assets/img/VisaArrival/andaman.webp"
                  className="img-fluid rounded-15"
                  alt="Agra"
                />
                <div className="image-caption">Andaman</div>
              </div>
              <div className="position-relative d-inline-block mx-2 image-item">
                <img
                  src="/assets/img/VisaArrival/maritius.avif"
                  className="img-fluid rounded-15"
                  alt="Agra"
                />
                <div className="image-caption">Maritius</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ------------------------------------------------------- SOUTH EAST ASIAN VACATIONS --------------------------------------------------------------*/}

      <div className="container text-center  packages-card mb-5">
        <div className="container">
          <h4 className="cards-heading">SOUTH EAST ASIAN VACATIONS</h4>
          <div className="slider-container w-3/4 m-auto">
            <div className="slider-inner mt-20">
              <Slider {...settings}>
                {southeast.map((pkg, index) => (
                  <Link to={`/SubPackages/${pkg.id}/${pkg.slug}`} key={index}>
                    <div className="package-card h-[450px] text-black rounded-xl">
                      <div className="package-image-container  bg-indigo-500 d-flex justify-center items-center rounded-t-xl">
                        <img
                          src={pkg.image}
                          alt={pkg.name}
                          className="package-image"
                        />
                      </div>
                      <p className="package-name mt-1">{pkg.name}</p>
                    </div>
                  </Link>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
      {/* -------------------------------------------------------  MOBILE BANNER--------------------------------------------------------------*/}

      <div className="mobile-banner">
        <img
          src="/assets/img/welcome.png"
          alt="img"
          style={{ height: "500px" }}
        ></img>
      </div>
      {/* -------------------------------------------------------  SCANDINAVIA --------------------------------------------------------------*/}
      <div className="container text-center">
        <div className="container">
          <h4 className="cards-heading">SCANDINAVIA</h4>
          <div className="slider-container w-3/4 m-auto">
            <div className="slider-inner mt-20">
              <Slider {...settings}>
                {scandinavia.map((pkg, index) => (
                  <Link to={`/SubPackages/${pkg.id}/${pkg.slug}`} key={index}>
                    <div className="package-card h-[450px] text-black rounded-xl">
                      <div className="package-image-container  bg-indigo-500 d-flex justify-center items-center rounded-t-xl">
                        <img
                          src={pkg.image}
                          alt={pkg.name}
                          className="package-image"
                        />
                      </div>
                      <p className="package-name mt-1">{pkg.name}</p>
                    </div>
                  </Link>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
      {/* -------------------------------------------------------  VACATION FOR ALL --------------------------------------------------------------*/}
      <div className="container py-5 d-flex flex-column justify-content-center align-items-center">
        <div
          className="py-5 d-flex flex-column justify-content-center align-items-center Holiday"
          style={{}}
        >
          <h2
            style={{
              color: "white",
              fontWeight: "700",
              fontFamily: "sans-serif",
            }}
          >
            VACATION FOR ALL
          </h2>
          <p
            className="my-3  mb-5"
            style={{
              color: "rgb(36, 183, 116)",
              fontSize: "28px",
              fontFamily: "Dancing Script",
              lineHeight: "1.2px",
            }}
          >
            Travellers
          </p>

          <div className="row align-items-center image-grid">
            <div className="mobile-res">
              <div className="position-relative d-inline-block mx-2 image-item">
                <img
                  src="/assets/img/VisaArrival/North-india.avif"
                  className="img-fluid rounded-15"
                  alt="North-india"
                />
                <div className="image-caption">North India</div>
              </div>
              <div className="position-relative d-inline-block mx-2 image-item">
                <img
                  src="/assets/img/VisaArrival/South-india.avif"
                  className="img-fluid rounded-15"
                  alt="South-india.avif"
                />
                <div className="image-caption">South India</div>
              </div>
            </div>
            <div className="mobile-res">
              <div className="position-relative d-inline-block mx-2 image-item">
                <img
                  src="/assets/img/VisaArrival/Honeymoon.avif"
                  className="img-fluid rounded-15"
                  alt="Agra"
                />
                <div className="image-caption">Honeymoon</div>
              </div>
              <div className="position-relative d-inline-block mx-2 image-item">
                <img
                  src="/assets/img/VisaArrival/Spiritual.avif"
                  className="img-fluid rounded-15"
                  alt="Agra"
                />
                <div className="image-caption">Spiritual</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* -------------------------------------------------------  MIDDLE EAST --------------------------------------------------------------*/}
      <div className="container text-center">
        <div className="container">
          <h4 className="cards-heading">MIDDLE EAST</h4>
          <div className="slider-container w-3/4 m-auto">
            <div className="slider-inner mt-20">
              <Slider {...settings}>
                {middleeast.map((pkg, index) => (
                  <Link to={`/SubPackages/${pkg.id}/${pkg.slug}`} key={index}>
                    <div className="package-card h-[450px] text-black rounded-xl">
                      <div className="package-image-container  bg-indigo-500 d-flex justify-center items-center rounded-t-xl">
                        <img
                          src={pkg.image}
                          alt={pkg.name}
                          className="package-image"
                        />
                      </div>
                      <p className="package-name mt-1">{pkg.name}</p>
                    </div>
                  </Link>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
      {/* -------------------------------------------------------  Contact Us BUTTON --------------------------------------------------------------*/}

      <div class="d-flex justify-content-center contact-button">
        <button class="btn  contact-btn">Contact Us!</button>
      </div>
    </div>
  );
};

export default Homepagesecond;
