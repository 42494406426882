import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Home.css";
import { searchTours } from "./ApiService";

import Floatbutton from "./Floatbutton";
import { AiFillCheckCircle } from "react-icons/ai";
import Header from "../navbar/Header";
import { Helmet } from "react-helmet";
import Homepagesecond from "./Homepagesecond";
import Homepagefirst from "./Homepagefirst";

const Home = () => {
  //--------------------search function--------------------------------------------

  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputValue.trim()) {
      setError("Please enter city name.");
      return;
    }
    try {
      const data = await searchTours(inputValue);
      console.log("Search Results:", data);
      setError(null);
      navigate("/searchComponent", { state: { searchResults: data } });
    } catch (error) {
      console.error("Failed to fetch search results:", error);
      setError("Failed to fetch search results. Please try again later.");
    }
  };

  return (
    <>
      <Helmet>
        <title>
          Discover Amazing Tour Packages from Chennai | Welcome Tours and
          Travels
        </title>
        <meta
          name="description"
          content="Explore a variety of affordable tour packages from Chennai with Welcome Tours and Travels. Call us at 7373339933 to book your dream trip now!"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <Header />

      <div className="home-page ">
        <div style={{ fontFamily: "Kalnia" }} className="font-size-72-26">
          Your Dream Vacation
          <br />
          Is Finally Here
        </div>
      </div>
      <div
        style={{ backgroundColor: "black", marginRight: "0px" }}
        className="row align-items-center justify-content-center"
      >
        <marquee
          className="container"
          style={{ backgroundColor: "black", color: "white", width: "100%" }}
        >
          <div className="d-flex flex-row ">
            <div
              className="d-flex align-items-center"
              style={{ marginRight: "50px" }}
            >
              <AiFillCheckCircle style={{ color: "green", fontSize: "25px" }} />
              <span className="ms-2">Visa Success Rate</span>
            </div>
            <div
              className="d-flex align-items-center"
              style={{ marginRight: "50px" }}
            >
              <AiFillCheckCircle style={{ color: "green", fontSize: "25px" }} />
              <span className="ms-2">100% Customized Trips</span>
            </div>
            <div
              className="d-flex align-items-center"
              style={{ marginRight: "50px" }}
            >
              <AiFillCheckCircle style={{ color: "green", fontSize: "25px" }} />
              <span className="ms-2">24x7 Concierge</span>
            </div>
            <div
              className="d-flex align-items-center"
              style={{ marginRight: "50px" }}
            >
              <img
                src="/assets/img/googlefb.png"
                style={{ height: "25px" }}
                alt="Rating"
              />
              <span className="ms-2">4.5 Rated</span>
            </div>
          </div>
        </marquee>
      </div>

      <div className="container-fluid ">
        <div className="container pb-5">
          <div className="shadow">
            <div className="shadow-text">
              <h3 style={{ textAlign: "left" }}>
                <span className="DESTINATION-span ">DESTINATION</span>
              </h3>
            </div>

            <div className="shadowp">Where would you like to go? </div>

            <div
              style={{
                minHeight: "60px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <form
                onSubmit={handleSubmit}
                style={{ display: "flex", width: "100%" }}
              >
                <div className="row" style={{ flex: "1", fontSize: "30px" }}>
                  <div className="row-md-12 col-xs-9">
                    <input
                      type="text"
                      className="form-control p-4"
                      id="searchform"
                      placeholder={error ? error : "e.g. Delhi, Amritsar"}
                      value={inputValue}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div
                    className="row-md-3 col-xs-3"
                    style={{ flex: "none", fontSize: "30px" }}
                  >
                    <button className="btn ml-3 search-button" type="submit">
                      PLAN YOUR TRIP
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Homepagefirst />

      <Homepagesecond />

      {/* ------------------------------------------------------- FLOAT BUTTON --------------------------------------------------------------*/}

      <Floatbutton />
    </>
  );
};

export default Home;
