import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Carousel } from "react-bootstrap";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Home.css";

import { fetchLatestTours, fetchPackages } from "./ApiService";

const Homepagefirst = () => {
  const [southindia, setSouthindia] = useState([]);
  const [northindia, setNorthindia] = useState([]);
  const [latest, setLatest] = useState([]);

  useEffect(() => {
    getPackages(1, setSouthindia);
    getPackages(2, setNorthindia);

    getLatestTours();
  }, []);

  // -----------packeges-----------------------------

  const getPackages = async (id, setState) => {
    try {
      const data = await fetchPackages(id);
      setState(data);
    } catch (error) {
      console.error("Error fetching packages:", error);
    }
  };

  //-----------------------------------------Latest tour------------------------------------------------
  const getLatestTours = async () => {
    try {
      const data = await fetchLatestTours();
      setLatest(data);
    } catch (error) {
      console.error("Error fetching latest tours:", error);
    }
  };

  //-----------------------------------------slider setting------------------------------------------------
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.3,
          slidesToScroll: 1,
          autoplaySpeed: 3000,
        },
      },
    ],
  };
  const latestsettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.3,
          slidesToScroll: 1,
          autoplaySpeed: 5000,
        },
      },
    ],
  };

  //--------------------page refresh--------------------------------------------

  window.addEventListener("load", function () {
    window.scrollTo(0, 0);
  });

  const carouselRef = useRef(null);
  let startX = 0;
  let endX = 0;

  const handleTouchStart = (e) => {
    startX = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    endX = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    if (startX - endX > 50) {
      carouselRef.current.next();
    } else if (endX - startX > 50) {
      carouselRef.current.prev();
    }
  };
  return (
    <div>
      <div className="container text-center packages-card" id="packages-north">
        <div className="container">
          <h4 className="cards-heading">NORTH INDIA TOURS</h4>
          <div className="slider-container w-3/4 m-auto">
            <div className="slider-inner mt-20">
              <Slider {...settings}>
                {northindia.map((pkg, index) => (
                  <Link to={`/SubPackages/${pkg.id}/${pkg.slug}`} key={index}>
                    <div className="package-card h-[450px] text-black rounded-xl">
                      <div className="package-image-container  bg-indigo-500 d-flex justify-center items-center rounded-t-xl">
                        <img
                          src={pkg.image}
                          alt={pkg.name}
                          className="package-image"
                        />
                      </div>
                      <p className="package-name mt-1">{pkg.name}</p>
                    </div>
                  </Link>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>

      <div className="container text-center  packages-card">
        <div className="container">
          <h4 className="cards-heading">SOUTH INDIA TOURS</h4>
          <div className="slider-container w-3/4 m-auto">
            <div className="slider-inner mt-20">
              <Slider {...settings}>
                {southindia.map((pkg, index) => (
                  <Link to={`/SubPackages/${pkg.id}/${pkg.slug}`} key={index}>
                    <div className="package-card h-[450px] text-black rounded-xl">
                      <div className="package-image-container  bg-indigo-500 d-flex justify-center items-center rounded-t-xl">
                        <img
                          src={pkg.image}
                          alt={pkg.name}
                          className="package-image"
                        />
                      </div>
                      <p className="package-name mt-1">{pkg.name}</p>
                    </div>
                  </Link>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
      <div className="container text-center  packages-card">
        <div className="container">
          <h4 className="cards-heading">LATEST TOURS</h4>

          <div className=" w-3/4 ">
            <div className="slider-inner latest-tour-card">
              <Slider {...latestsettings}>
                {latest.map((lat, index) => (
                  <Link to={`/SubPackages/${lat.id}`} key={index}>
                    <div className="package-card text-black rounded-xl">
                      <div className="package-image-container  bg-indigo-500  rounded-t-xl">
                        <img src={lat.image} alt={lat.name} />
                      </div>
                      <p className="package-name mt-3">{lat.name}</p>
                    </div>
                  </Link>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
      {/* ------------------------------------------------------- Your Dream-web --------------------------------------------------------------*/}
      <div
        className="container d-none d-md-flex"
        style={{
          backgroundColor: "#f8f9fa",
          color: "black",
          fontFamily: "Kalnia",
          padding: "50px",
          marginTop: "20px",
          height: "600px",
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          width: "1200px",
        }}
      >
        <div className="row" style={{ width: "1200px" }}>
          <div className="col-md-8 d-none d-md-flex">
            <h1
              className="text-md-start text-center"
              style={{ fontSize: "90px", paddingLeft: "50px" }}
            >
              Your
              <br></br>Dream
              <br />
              Travel In
              <br />3 Steps
            </h1>
          </div>
          <div className="col-md-3 align-items-left d-none d-md-flex ">
            <Carousel controls={false} indicators={false} interval={3000}>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="/assets/img/caraousel1.png"
                  alt="First slide"
                  style={{
                    height: "200px",
                    width: "10px",
                    objectFit: "cover",
                    fontFamily: "Montserrat, serif",
                  }}
                />
                <div
                  className="mt-5"
                  style={{ fontFamily: "Montserrat, serif" }}
                >
                  <h5
                    style={{
                      fontFamily: "Montserrat, serif",
                      textAlign: "center",
                    }}
                  >
                    <b>1. You Describe</b>
                  </h5>
                  <h5
                    style={{
                      fontFamily: "Montserrat, serif",
                      fontWeight: "400",
                      fontSize: "19px",
                      lineHeight: "29px",
                      textAlign: "center",
                    }}
                  >
                    You describe Adventure or beach apartment or resort? Let's
                    know your travel preferences quickly and easily online.
                  </h5>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="/assets/img/caraousel2.png"
                  alt="Second slide"
                  style={{ height: "200px", width: "10px", objectFit: "cover" }}
                />
                <div className="mt-5 ">
                  <h5
                    style={{
                      fontFamily: "Montserrat, serif",
                      textAlign: "center",
                    }}
                  >
                    <b>2. We Organize</b>
                  </h5>
                  <h5
                    style={{
                      fontFamily: "Montserrat, serif",
                      fontWeight: "400",
                      fontSize: "19px",
                      lineHeight: "29px",
                      textAlign: "center",
                    }}
                  >
                    Based on preferences and wishes, our Welcome Tours and
                    Travels experts organize tailor-made trips for you over the
                    phone.
                  </h5>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="/assets/img/caraousel3.png"
                  alt="Third slide"
                  style={{ height: "200px", width: "10px", objectFit: "cover" }}
                />
                <div className="mt-5">
                  <h5
                    style={{
                      fontFamily: "Montserrat, serif",
                      textAlign: "center",
                    }}
                  >
                    <b>3. You Travel!</b>
                  </h5>
                  <h5
                    style={{
                      fontFamily: "Montserrat, serif",
                      fontWeight: "400",
                      fontSize: "19px",
                      lineHeight: "29px",
                      textAlign: "center",
                    }}
                  >
                    Happy with our offer? Pack your suitcase as your dream
                    travel tour is about to start!
                  </h5>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </div>
      {/* ------------------------------------------------------- Your Dream-mobile --------------------------------------------------------------*/}
      <div
        className="container  d-sm-flex d-md-none Your-Dream-mobile"
        style={{
          backgroundColor: "#f8f9fa",
          color: "black",
          fontFamily: "Lato,serif",
          padding: "30px 30px 0px 30px ",
          marginTop: "20px",
          width: "90%",
          height: "450px",
          fontWeight: "500",

          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        }}
      >
        <div className="col" style={{ width: "300px" }}>
          <div className="col-md-12  d-md-flex">
            <p
              className="text-md-start text-center"
              style={{
                marginBottom: "0px",
                fontSize: "20px",
                fontWeight: "500",
                lineHeight: "24px",
              }}
            >
              Your Dream Travel In 3 Steps
            </p>
          </div>
          <div className="col-md-3 align-items-left  d-md-flex ">
            <Carousel
              controls={false}
              indicators={false}
              interval={3000}
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
              style={{ height: "400px" }}
            >
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="/assets/img/caraousel1.png"
                  alt="First slide"
                  style={{
                    height: "230px",
                    width: "10px",
                    objectFit: "cover",
                    fontFamily: "Montserrat, serif",
                  }}
                />
                <div
                  className="mt-4"
                  style={{ fontFamily: "Montserrat, serif" }}
                >
                  <h6
                    style={{
                      fontFamily: "Montserrat, serif",
                      textAlign: "center",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    <b>1. You Describe</b>
                  </h6>
                  <h5
                    style={{
                      fontFamily: "Montserrat, serif",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "21px",
                      textAlign: "center",
                      color: "black",
                    }}
                  >
                    1.You describe Adventure or beach apartment or resort? Let's
                    know your travel preferences quickly and easily online.
                  </h5>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100 "
                  src="/assets/img/caraousel2.png"
                  alt="Second slide"
                  style={{ height: "230px", width: "10px", objectFit: "cover" }}
                />
                <div className="mt-4 ">
                  <h6
                    style={{
                      fontFamily: "Montserrat, serif",
                      textAlign: "center",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    <b>2. We Organize</b>
                  </h6>
                  <h5
                    style={{
                      fontFamily: "Montserrat, serif",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "21px",
                      textAlign: "center",
                      color: "black",
                    }}
                  >
                    Based on preferences and wishes, our Welcome Tours and
                    Travels experts organize tailor-made trips for you over the
                    phone.
                  </h5>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="/assets/img/caraousel3.png"
                  alt="Third slide"
                  style={{ height: "230px", width: "10px", objectFit: "cover" }}
                />
                <div className="mt-4">
                  <h6
                    style={{
                      fontFamily: "Montserrat, serif",
                      textAlign: "center",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    <b>3. You Travel!</b>
                  </h6>
                  <p
                    style={{
                      fontFamily: "Montserrat, serif",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "21px",
                      textAlign: "center",
                      color: "black",
                    }}
                  >
                    Happy with our offer? Pack your suitcase as your dream
                    travel tour is about to start!
                  </p>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </div>

      <div class="container-fluid px-md-5  Home-center-cards my-5">
        <div class="row justify-content-center align-items-center py-5">
          <div class=" text-center mb-3 mb-md-0 ">
            <img
              src="/assets/img/landscape1.png"
              class="img-fluid rounded-3"
              alt="landscape1"
              style={{
                borderRadius: "25px",
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              }}
            />
          </div>
          <div class=" text-center">
            <img
              src="/assets/img/landscape2.png"
              class="img-fluid rounded-3"
              alt="landscape2"
              style={{
                borderRadius: "25px",
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              }}
            />
          </div>
        </div>
      </div>
      {/* ------------------------------------------------------- QUICK GETAWAY DESTINATIONS --------------------------------------------------------------*/}

      <div
        className="container-fluid bg-registration d-none d-md-block "
        style={{ backgroundColor: "rgb(18, 27, 34)" }}
      >
        <div className="container  d-flex flex-column justify-content-center align-items-center inside-banner">
          <h2 style={{ color: "rgb(42, 204, 101)", fontWeight: "700" }}>
            QUICK GETAWAY DESTINATIONS
          </h2>
          <p
            style={{
              color: "rgb(42, 204, 101)",
              fontSize: "18px",
              fontFamily: "Montserrat,serif",
            }}
          >
            Ideal for 3-5 days trip
          </p>
          <div
            className="row align-items-center"
            style={{
              display: "flex",
              flexDirection: "row",
              height: "400px",
              gap: "20px",
            }}
          >
            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <div className="position-relative d-inline-block">
                <img
                  src="/assets/img/QuickGetaway/agra.jpeg"
                  className="img-fluid rounded-15"
                  alt="Kerala"
                  style={{
                    height: "200px",
                    width: "350px",
                    objectFit: "cover",
                    borderRadius: "20px",
                  }}
                />
                <div className="QUICK-GETAWAy-text">
                  <h1>Agra</h1>
                  <p style={{ color: "white" }}>from₹320</p>
                </div>
              </div>

              <div className="d-flex flex-row gap-10">
                <div className="position-relative d-inline-block">
                  <img
                    src="/assets/img/QuickGetaway/kerala.webp"
                    className="img-fluid rounded-15"
                    alt="Kerala"
                    style={{
                      height: "200px",
                      width: "175px",
                      objectFit: "cover",
                      borderRadius: "20px",
                    }}
                  />
                  <div className="QUICK-GETAWAy-text">
                    <h1>kerala</h1>
                    <p style={{ color: "white" }}>from₹320</p>
                  </div>
                </div>
                <div className="position-relative d-inline-block">
                  <img
                    src="/assets/img/QuickGetaway/goa.jpeg"
                    className="img-fluid rounded-15"
                    alt="Goa"
                    style={{
                      height: "200px",
                      width: "165px",
                      objectFit: "cover",
                      borderRadius: "20px",
                      marginLeft: "10px",
                    }}
                  />
                  <div className="QUICK-GETAWAy-text">
                    <h1>goa</h1>
                    <p style={{ color: "white" }}>from₹320</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="position-relative ">
              <img
                src="/assets/img/QuickGetaway/Himachal.jpeg"
                className="img-fluid rounded-15"
                alt="Himachal"
                style={{
                  height: "410px",
                  width: "350px",
                  objectFit: "cover",
                  borderRadius: "20px",
                }}
              />
              <div className="QUICK-GETAWAy-text-center">
                <h1>Himachal</h1>
                <p style={{ color: "white" }}>from₹320</p>
              </div>
            </div>

            <div className="d-none d-md-flex flex-column gap-10">
              <div className="position-relative d-inline-block">
                <img
                  src="/assets/img/QuickGetaway/mumbai.jpg"
                  className="img-fluid rounded-15"
                  alt="Mumbai"
                  style={{
                    height: "200px",
                    width: "350px",
                    objectFit: "cover",
                    borderRadius: "20px",
                  }}
                />
                <div className="QUICK-GETAWAy-text">
                  <h1>Mumbai</h1>
                  <p style={{ color: "white" }}>from₹320</p>
                </div>
              </div>
              <div className="position-relative d-inline-block">
                <img
                  src="/assets/img/QuickGetaway/tamilNadu.jpeg"
                  className="img-fluid rounded-15"
                  alt="Tamil Nadu"
                  style={{
                    height: "200px",
                    width: "350px",
                    objectFit: "cover",
                    marginTop: "10px",
                    borderRadius: "20px",
                  }}
                />
                <div className="QUICK-GETAWAy-text">
                  <h1>TamilNadu</h1>
                  <p style={{ color: "white" }}>from₹320</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ------------------------------------------------------- QUICK GETAWAY DESTINATIONS mobile --------------------------------------------------------------*/}

      <div
        className="container-fluid quick-contain d-block d-lg-none"
        style={{ backgroundColor: "rgb(18, 27, 34)" }}
      >
        <h2
          style={{ color: "rgb(42, 204, 101)", fontWeight: "700" }}
          className="text-uppercase"
        >
          QUICK GETAWAY DESTINATIONS
        </h2>
        <p
          style={{
            color: "rgb(42, 204, 101)",
            fontSize: "18px",
            fontFamily: "Montserrat,serif",
          }}
        >
          Ideal for 3-5 days trip
        </p>
        <div className="container  quick-getaways">
          <div className="quick-getaways-images">
            <div className="quick-getaways-cards">
              <img
                src="/assets/img/EuropeanHolidays/italy1.webp"
                className="img-fluid rounded-15"
                alt="img"
              />
              <p>Italy</p>
            </div>
            <div className="quick-getaways-cards">
              <img
                src="/assets/img/EuropeanHolidays/turkey.webp"
                className="img-fluid rounded-15"
                alt="img"
              />
              <p>Turkey</p>
            </div>
            <div className="quick-getaways-cards">
              <img
                src="/assets/img/EuropeanHolidays/austria.webp"
                className="img-fluid rounded-15"
                alt="img"
              />
              <p>Austria</p>
            </div>
          </div>
          <div className="quick-getaways-images">
            <div className="quick-getaways-cards">
              <img
                src="/assets/img/EuropeanHolidays/norway.webp"
                className="img-fluid rounded-15"
                alt="img"
              />
              <p>Norwey</p>
            </div>
            <div className="quick-getaways-cards">
              <img
                src="/assets/img/EuropeanHolidays/turkey2.webp"
                className="img-fluid rounded-15"
                alt="img"
              />
              <p>Turkey</p>
            </div>
            <div className="quick-getaways-cards">
              <img
                src="/assets/img/EuropeanHolidays/finland.webp"
                className="img-fluid rounded-15"
                alt="img"
              />
              <p>Finland</p>
            </div>
          </div>
          <div className="quick-getaways-images">
            <div className="quick-getaways-cards">
              <img
                src="/assets/img/EuropeanHolidays/spain.webp"
                className="img-fluid rounded-15"
                alt="img"
              />
              <p>Spain</p>
            </div>
            <div className="quick-getaways-cards">
              <img
                src="/assets/img/EuropeanHolidays/france.webp"
                className="img-fluid rounded-15"
                alt="img"
              />
              <p>France</p>
            </div>
            <div className="quick-getaways-cards">
              <img
                src="/assets/img/EuropeanHolidays/switerland.webp"
                className="img-fluid rounded-15"
                alt="img"
              />
              <p>Switzerland</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Homepagefirst;
